import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      types: 'app/types',
      states: 'app/states',
      filter: 'app/filter',
      permissions: 'user/permissions'
    })
  },
  methods: {
//    ...mapMutations({
//
//    }),
    ...mapActions({
      fetchUsers: 'user/fetchUsers',
      fetchMe: 'user/fetchMe',
      fetchUser: 'user/fetchUser',
      createUser: 'user/createUser',
      updateUser: 'user/updateUser',
    })
  }
}
